<template>
  <div class="flex items-center justify-center min-h-screen bg-gray-900 p-4">
    <!-- Main Card -->
    <div class="bg-gray-800 rounded-3xl shadow-xl p-8 w-full max-w-md mx-4">
      <!-- Signup Header -->
      <h1 class="text-3xl font-bold text-center text-white mb-2">Create Account</h1>
      <p class="text-sm text-center text-gray-400 mb-8">Join us and get started!</p>

      <!-- Signup Form -->
      <form @submit.prevent="handleSignup" class="space-y-6">
        <!-- Name Input -->
        <div>
          <input 
            type="text" 
            v-model="name" 
            placeholder="Enter your name" 
            class="w-full px-4 py-3 rounded-lg bg-gray-700 border border-transparent text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500"
            required 
          />
        </div>

        <!-- Email Input -->
        <div>
          <input 
            type="email" 
            v-model="email" 
            placeholder="Enter your email" 
            class="w-full px-4 py-3 rounded-lg bg-gray-700 border border-transparent text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500"
            required 
          />
        </div>

        <!-- Password Input -->
        <div>
          <input 
            type="password" 
            v-model="password" 
            placeholder="Create password" 
            class="w-full px-4 py-3 rounded-lg bg-gray-700 border border-transparent text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500"
            required 
          />
          <p v-if="passwordError" class="text-red-500 text-sm mt-2">{{ passwordError }}</p>
        </div>

        <!-- Confirm Password Input -->
        <div>
          <input 
            type="password" 
            v-model="confirmPassword" 
            placeholder="Confirm password" 
            class="w-full px-4 py-3 rounded-lg bg-gray-700 border border-transparent text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500"
            required 
          />
          <p v-if="confirmPasswordError" class="text-red-500 text-sm mt-2">{{ confirmPasswordError }}</p>
        </div>

        <!-- Signup Button -->
        <button 
          type="submit" 
          class="w-full bg-green-600 text-white py-3 rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-300"
        >
          Sign Up
        </button>
      </form>

      <!-- Or Continue with Google -->
      <div class="text-center mt-6">
        <p class="text-gray-500 mb-4">Or continue with</p>
        <div class="flex justify-center">
          <button @click="handleGoogleSignup" class="bg-gray-700 p-3 rounded-full shadow-md">
            <img src="https://firebasestorage.googleapis.com/v0/b/nexusbot-connect.appspot.com/o/google-removebg-preview.png?alt=media&token=2bfb4876-c5df-4d3f-8a46-6c4ff9075fb9" alt="Google" class="h-8 w-8">
          </button>
        </div>
      </div>

      <!-- Already have an account -->
      <p class="mt-6 text-center text-sm text-gray-400">
        Already have an account? 
        <router-link to="/login" class="text-green-500 hover:text-green-400 ml-1">Login</router-link>
      </p>
    </div>
  </div>
</template>

<script>
import { createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, sendEmailVerification } from 'firebase/auth';
import { auth } from '../../firebase';

export default {
  name: 'Signup',
  data() {
    return {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      passwordError: '',
      confirmPasswordError: ''
    };
  },
  methods: {
    validatePassword() {
      const passwordPattern = /^(?=.*[A-Z])(?=.*[!@#$&*]).{8,}$/;
      if (!passwordPattern.test(this.password)) {
        this.passwordError = 'Password must be at least 8 characters long, contain one uppercase letter and one symbol.';
        return false;
      }
      this.passwordError = '';
      return true;
    },
    validateConfirmPassword() {
      if (this.password !== this.confirmPassword) {
        this.confirmPasswordError = 'Passwords do not match.';
        return false;
      }
      this.confirmPasswordError = '';
      return true;
    },
    async handleSignup() {
      if (!this.validatePassword() || !this.validateConfirmPassword()) return;
      
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, this.email, this.password);
        const user = userCredential.user;

        // Send email verification
        await sendEmailVerification(user);
        
        // Show alert to user
        alert('Account created! Please check your email to verify your account.');
        
        // Optionally, redirect to another page
        this.$router.push('/Login');
        
      } catch (error) {
        console.error("Error signing up:", error.message);
      }
    },
    async handleGoogleSignup() {
      const provider = new GoogleAuthProvider();
      try {
        const result = await signInWithPopup(auth, provider);
        console.log('User Info:', result.user);
        this.$router.push('/Nmap');
      } catch (error) {
        console.error("Error with Google signup:", error.message);
      }
    }
  }
}
</script>

<style scoped>
/* Custom styles for signup page */
.bg-gradient-to-r {
  background: linear-gradient(to right, #1f2937, #374151); /* Dark gradient */
}

form input:focus {
  border: none;
  outline: none;
}

button svg {
  transition: transform 0.3s ease;
}

button:hover svg {
  transform: translateX(5px);
}

.p-4 {
  padding: 1rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}
</style>
