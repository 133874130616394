<template>
    <div class="bg-gray-900 min-h-screen text-white p-6">
      <!-- Header -->
      <header class="text-center mb-8 py-6 bg-gray-900  ">
        <h1 class="text-3xl font-bold">Report Bug and Fix</h1>
      </header>
  
      <!-- Report Bg Button -->
      <div class="mb-8 text-center">
        <button @click="showReportBugForm" class="bg-red-600 hover:bg-red-800 text-white font-bold py-3 px-6 rounded-lg shadow-md transition duration-300">
          submit Report Bug
        </button>
      </div>
  
      <!-- Bug Report Table -->
      <div class="bg-gray-700 p-6 rounded-md shadow-md mb-8">
        <h2 class="text-2xl font-bold mb-6 text-center">Fixed Bugs</h2>
  
        <div class="overflow-x-auto">
          <!-- Mobile View -->
          <div class="block md:hidden">
            <div v-for="bugReport in bugReports" :key="bugReport.issue_number" class="bg-gray-600 mb-4 p-4 rounded-md shadow-md">
              <h3 class="text-lg font-bold">Issue: {{ bugReport.issue_number }}</h3>
              <p class="truncate">{{ truncateDescription(bugReport.description) }}</p>
              <button @click="showDescriptionModal(bugReport.description)" class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-3 rounded-md mt-2 transition duration-300">
                Read More
              </button>
              <div class="mt-2">
                <span :class="getStatusClass(bugReport.status)" class="px-3 py-1 rounded-full font-semibold">
                  Status: {{ bugReport.status }}
                </span>
              </div>
              <div class="mt-2">
                <span :class="getBugTypeClass(bugReport.bug_type)" class="px-3 py-1 rounded-full font-semibold">
                  Type: {{ bugReport.bug_type }}
                </span>
              </div>
            </div>
          </div>
  
          <!-- Desktop View -->
          <div class="hidden md:block">
            <table class="table-auto w-full text-left border-separate border-spacing-2">
              <thead class="bg-gray-600">
                <tr>
                  <th class="p-4">Issue</th>
                  <th class="p-4">Description</th>
                  <th class="p-4">Status</th>
                  <th class="p-4">Bug Type</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="bugReport in bugReports" :key="bugReport.issue_number" class="bg-gray-600 hover:bg-gray-500 transition duration-300">
                  <td class="p-4">{{ bugReport.issue_number }}</td>
                  <td class="p-4">
                    <p class="truncate">{{ truncateDescription(bugReport.description) }}</p>
                    <button @click="showDescriptionModal(bugReport.description)" class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-3 rounded-md mt-2 transition duration-300">
                      Read More
                    </button>
                  </td>
                  <td class="p-4">
                    <span :class="getStatusClass(bugReport.status)" class="px-3 py-1 rounded-full font-semibold">
                      {{ bugReport.status }}
                    </span>
                  </td>
                  <td class="p-4">
                    <span :class="getBugTypeClass(bugReport.bug_type)" class="px-3 py-1 rounded-full font-semibold">
                      {{ bugReport.bug_type }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
  
    <!-- Bug Report Form -->
<dialog id="bugReportForm" ref="bugReportForm" class="bg-gray-800 text-white rounded-lg p-6 w-full max-w-md mx-auto relative">
  <!-- Close Icon -->
  <button @click="closeBugReport" class="absolute top-2 right-2 text-white hover:text-gray-400 focus:outline-none">
    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
    </svg>
  </button>

  <h2 class="text-2xl font-bold mb-4">Report Bug</h2>
  <div class="mb-4">
    <label class="block text-sm font-bold mb-2" for="bugType">Bug Type</label>
    <select v-model="selectedBugType" class="w-full p-3 bg-gray-700 text-white rounded-lg shadow-sm">
      <option v-for="type in bugTypes" :key="type" :value="type">{{ type }}</option>
    </select>
  </div>
  <div class="mb-4">
    <label class="block text-sm font-bold mb-2" for="description">Description</label>
    <textarea v-model="description" rows="5" class="w-full p-3 bg-gray-700 text-white rounded-lg shadow-sm"></textarea>
  </div>
  <button @click="sendBugReport" class="bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded-lg shadow-md">
    Submit Bug Report
  </button>
</dialog>

  
      <!-- Full Description Modal -->
      <dialog id="descriptionModal" ref="descriptionModal" class="bg-gray-800 text-white rounded-lg p-6 w-full max-w-md mx-auto">
        <h2 class="text-2xl font-bold mb-4">Full Description</h2>
        <p>{{ fullDescription }}</p>
        <button @click="closeDescriptionModal" class="bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded-lg shadow-md mt-4">
          Close
        </button>
      </dialog>
    </div>
  </template>
  
  
  <script>
  import axios from 'axios';
  import { getAuth } from "firebase/auth"; // Assuming you're using Firebase for auth
  export default {
  data() {
    return {
      selectedBugType: 'Security Issue',
      description: '',
      bugTypes: ['Security Issue', 'UI Issue', 'Functionality Issue', 'Other'],
      bugReports: [],
      idToken: null,
      fullDescription: '',
    };
  },
  mounted() {
    this.fetchIdToken();
  },
  methods: {
    async fetchIdToken() {
      const auth = getAuth();
      try {
        const user = auth.currentUser;
        if (user) {
          this.idToken = await user.getIdToken();
          this.fetchBugReports();
        } else {
          alert('No user is signed in.');
        }
      } catch (error) {
        console.error('Error fetching ID token:', error);
      }
    },
    async fetchBugReports() {
      if (!this.idToken) return;

      try {
        const response = await axios.get('https://api.nexusbot.app/bug-reports', {
          headers: {
            'Authorization': `Bearer ${this.idToken}`,
          },
        });
        this.bugReports = response.data;
      } catch (error) {
        console.error('Error fetching bug reports:', error);
      }
    },
    async sendBugReport() {
      if (!this.idToken) return;

      const bugReport = {
        bugType: this.selectedBugType,
        description: this.description,
      };

      try {
        const response = await axios.post('https://api.nexusbot.app/report-bug', bugReport, {
          headers: {
            'Authorization': `Bearer ${this.idToken}`,
          },
        });
        if (response.status === 200) {
          this.description = '';
          this.selectedBugType = 'Type 1';
          this.fetchBugReports();
          this.$refs.bugReportForm.close();
          alert('Bug report sent successfully');
        }
      } catch (error) {
        console.error('Error sending bug report:', error);
      }
    },
    showReportBugForm() {
      this.$refs.bugReportForm.showModal();
    },
    closeBugReport() {
      this.$refs.bugReportForm.close(); // Close the dialog
    },
    truncateDescription(description) {
      return description.length > 50 ? description.slice(0, 50) + '...' : description;
    },
    showDescriptionModal(description) {
      this.fullDescription = description;
      this.$refs.descriptionModal.showModal();
    },
    closeDescriptionModal() {
      this.$refs.descriptionModal.close();
    },
    getStatusClass(status) {
      if (status === 'Fixed') {
        return 'bg-green-500 text-white';
      }
      switch (status) {
        case 'Stuck':
          return 'bg-gray-500 text-gray-300';
        case 'In progress':
          return 'bg-green-500 text-white';
        case 'QA rejected':
          return 'bg-red-500 text-white';
        case 'QA verified':
          return 'bg-blue-500 text-white';
        default:
          return 'bg-gray-500 text-white';
      }
    },
    getBugTypeClass(type) {
      switch (type) {
        case 'Story':
          return 'bg-purple-500 text-white';
        case 'Task':
          return 'bg-teal-500 text-white';
        case 'Bug':
          return 'bg-orange-500 text-white';
        default:
          return 'bg-gray-500 text-white';
      }
    },
  },
};

  </script>
<style scoped>
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

dialog[open] {
  display: block;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .table-auto {
    display: none;
  }
}

@media (min-width: 769px) {
  .mobile-only {
    display: none;
  }
}

/* Button Style */
button {
  cursor: pointer;
}

button:hover {
  opacity: 0.9;
}

button:focus {
  outline: none;
}
</style>
