<template>
  <div id="app">
    <Navbar />
    <router-view v-if="!loading"></router-view> <!-- Display routed components once loading is done -->
    <div v-else class="loading">Loading...</div> <!-- Show loading message while checking auth state -->
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';
import { ref, onMounted } from 'vue';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import { useRouter } from 'vue-router';

export default {
  name: 'App',
  components: {
    Navbar
  },
  setup() {
    const router = useRouter();
    const loading = ref(true); // Loading state

    onMounted(() => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          console.log('User is logged in:', user);
          // Redirect to /HelloWorld if logged in
          router.push('/Nmap');
        } else {
          console.log('No user is logged in');
          // Redirect to /login if not logged in
          router.push('/Login');
        }
        loading.value = false; // Set loading to false after redirection is determined
      });
    });

    return { loading };
  }
}
</script>

<style>
/* Global styles */
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
}

.loading {
  font-size: 1.5rem;
  color: #333;
  padding: 20px;
}
</style>
