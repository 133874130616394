<template>
  <div class="min-h-screen bg-gray-900 text-white flex flex-col items-center p-4">
    <h1 class="text-3xl font-bold mb-6 text-left w-full">Sublist3r Scan</h1>

    <div class="w-full max-w-2xl bg-gray-800 p-6 rounded-lg shadow-lg">
      <!-- target input (Mandatory) -->
      <div class="mb-4">
        <label class="block text-gray-300 mb-2">Enter target:</label>
        <input
          v-model="target"
          type="text"
          placeholder="Enter target (e.g., example.com)"
          class="w-full p-3 bg-gray-700 rounded-lg text-white placeholder-gray-400"
        />
      </div>

      <!-- Action buttons -->
      <div class="flex space-x-4 mb-4">
        <button
          :disabled="!target"
          @click="sendSublist3rRequest"
          class="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-lg shadow-md disabled:opacity-50"
        >
          Scan
        </button>
        <button
          @click="clearFields"
          class="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg shadow-md"
        >
          Reset
        </button>
        <button
          @click="showHelp"
          class="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg shadow-md"
        >
          Help
        </button>
      </div>
    </div>

    <!-- Modal for Help -->
    <div v-if="isHelpModalVisible" class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-70">
      <div class="modal">
        <div class="modal-content">
          <h2 class="text-xl font-bold mb-4 text-left">Sublist3r Help</h2>
          <pre class="text-gray-300 whitespace-pre-wrap text-left">
usage: sublist3r [-h] -d DOMAIN [-b [BRUTEFORCE]] [-p PORTS] [-v [VERBOSE]]
                 [-t THREADS] [-e ENGINES] [-o OUTPUT] [-n]

OPTIONS:
  -h, --help            show this help message and exit
  -d DOMAIN, --domain DOMAIN
                        Domain name to enumerate its subdomains
          </pre>
          <button @click="closeHelpModal" class="mt-4 bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Modal styles */
.modal {
  max-width: 90%; /* Responsive width */
  max-height: 90%; /* Responsive height */
  overflow-y: auto; /* Allow vertical scrolling */
}

.modal-content {
  background-color: #1f2937; /* Gray-800 */
  padding: 1.5rem; /* Padding for content */
  border-radius: 0.5rem; /* Rounded corners */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5); /* Box shadow for depth */
}
</style>

<script>
import axios from 'axios';
import { auth } from '../firebase'; // Ensure the path to firebase is correct

export default {
  name: 'Sublist3rScan',
  data() {
    return {
      target: '', // User input for target (mandatory)
      idToken: null, // Firebase ID token for authentication
      isHelpModalVisible: false, // Modal visibility state
    };
  },
  methods: {
    async fetchIdToken() {
      try {
        const user = auth.currentUser;
        if (user) {
          this.idToken = await user.getIdToken();
        } else {
          alert('No user is signed in.');
        }
      } catch (error) {
        console.error('Error fetching ID token:', error);
      }
    },
    async sendSublist3rRequest() {
      if (!this.target) {
        alert('target is required.');
        return;
      }

      console.log('target:', this.target); // Log target for debugging

      try {
        // Preparing the payload with the mandatory fields
        const payload = {
         
          target: this.target, // Include the required target field (if needed)
        };

        console.log('Payload:', payload); // Log payload for debugging

        // Sending the request to the backend with Firebase ID token
        const response = await axios.post(
          'https://api.nexusbot.app/sublist3r', // Adjust the endpoint as necessary
          payload,
          {
            headers: {
              Authorization: `Bearer ${this.idToken}`, // ID token for authentication
              'Content-Type': 'application/json',
            },
          }
        );

        // Handling the success response
        alert(`Sublist3r scan request sent successfully.\nTask ID: ${response.data.task_id}\nMessage: ${response.data.message}`);
      } catch (error) {
        // Handling errors
        console.error('Error during API request:', error); // Log the error for debugging
        if (error.response && error.response.data) {
          alert(`Error: ${error.response.data.error || 'An unexpected error occurred.'}`);
        } else {
          alert('An unexpected error occurred. Please try again.');
        }
      }
    },
    clearFields() {
      // Clear the target input field
      this.target = '';
    },
    showHelp() {
      this.isHelpModalVisible = true; // Show the help modal
    },
    closeHelpModal() {
      this.isHelpModalVisible = false; // Close the help modal
    },
  },
  created() {
    // Fetch the Firebase ID token when the component is created
    this.fetchIdToken();
  },
};
</script>
