<template>
  <nav class="bg-gray-900 shadow-md py-4">
    <div class="container mx-auto flex justify-between items-center">
      <!-- Logo -->
      <div class="text-2xl font-bold text-green-400 md:pl-4 md:pr-4">
        <router-link to="/Nmap" class="hover:text-green-300">Nexusbot</router-link>
      </div>

      <!-- Hamburger Icon for Mobile -->
      <div class="md:hidden flex items-center px-4">
        <button @click="toggleSidebar" class="text-green-400 focus:outline-none">
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7"></path>
          </svg>
        </button>
      </div>

      <!-- Navigation Links for Desktop -->
      <div class="hidden md:flex items-center space-x-4">
        <!-- Login Link (only shown when not logged in) -->
        <template v-if="!isLoggedIn">
          <router-link 
            to="/login" 
            class="px-4 py-2 text-sm font-bold text-green-400 hover:bg-gray-800 hover:text-green-300 transition duration-300"
            :class="{ 'bg-gray-800 text-green-300': $route.path === '/login' }">
            Login
          </router-link>

          <!-- Signup Link (only shown when not logged in) -->
          <router-link 
            to="/signup" 
            class="px-4 py-2 text-sm font-bold text-green-400 hover:bg-gray-800 hover:text-green-300 transition duration-300"
            :class="{ 'bg-gray-800 text-green-300': $route.path === '/signup' }">
            Signup
          </router-link>
        </template>

        <!-- Links visible only when logged in -->
        <template v-if="isLoggedIn">
          <router-link 
            to="/Nmap" 
            class="px-4 py-2 text-sm font-bold text-green-400 hover:bg-gray-800 hover:text-green-300 transition duration-300"
            :class="{ 'bg-gray-800 text-green-300': $route.path === '/Nmap' }">
            Nmap
          </router-link>
          <router-link 
            to="/whois" 
            class="px-4 py-2 text-sm font-bold text-green-400 hover:bg-gray-800 hover:text-green-300 transition duration-300"
            :class="{ 'bg-gray-800 text-green-300': $route.path === '/Nmap' }">
            Basic Tools
          </router-link>

          <router-link 
            to="/whatweb" 
            class="px-4 py-2 text-sm font-bold text-green-400 hover:bg-gray-800 hover:text-green-300 transition duration-300"
            :class="{ 'bg-gray-800 text-green-300': $route.path === '/Nmap' }">
            whatweb
          </router-link>
          <router-link 
            to="/sublist3r" 
            class="px-4 py-2 text-sm font-bold text-green-400 hover:bg-gray-800 hover:text-green-300 transition duration-300"
            :class="{ 'bg-gray-800 text-green-300': $route.path === '/bugTracking' }">
            sublist3r
          </router-link>

          <router-link 
            to="/dashboard" 
            class="px-4 py-2 text-sm font-bold text-green-400 hover:bg-gray-800 hover:text-green-300 transition duration-300"
            :class="{ 'bg-gray-800 text-green-300': $route.path === '/dashboard' }">
            Dashboard
          </router-link>

          <router-link 
            to="/result" 
            class="px-4 py-2 text-sm font-bold text-green-400 hover:bg-gray-800 hover:text-green-300 transition duration-300"
            :class="{ 'bg-gray-800 text-green-300': $route.path === '/result' }">
            Result
          </router-link>

          <router-link 
            to="/bugTracking" 
            class="px-4 py-2 text-sm font-bold text-green-400 hover:bg-gray-800 hover:text-green-300 transition duration-300"
            :class="{ 'bg-gray-800 text-green-300': $route.path === '/bugTracking' }">
            Bug Reports
          </router-link>


          <!-- Logout Button -->
          <button 
            @click="logout" 
            class="px-4 py-2 text-sm font-bold text-white bg-red-600 hover:bg-red-700 rounded transition duration-300">
            Logout
          </button>
        </template>
      </div>
    </div>

    <!-- Mobile Sidebar Drawer -->
    <div v-if="isSidebarOpen" class="fixed inset-0 bg-black bg-opacity-75 z-40" @click="closeSidebar">
      <div class="absolute left-0 top-0 w-64 bg-gray-900 h-full shadow-lg z-50">
        <div class="p-4">
          <router-link to="/" class="text-2xl font-bold text-green-400">Nexusbot</router-link>
        </div>
        <div class="p-4 space-y-4">
          <!-- Login Link (only shown when not logged in) -->
          <template v-if="!isLoggedIn">
            <router-link 
              to="/login" 
              class="block px-4 py-2 text-sm font-bold text-green-400 hover:bg-gray-800 hover:text-green-300 transition duration-300"
              :class="{ 'bg-gray-800 text-green-300': $route.path === '/login' }">
              Login
            </router-link>

            <!-- Signup Link (only shown when not logged in) -->
            <router-link 
              to="/signup" 
              class="block px-4 py-2 text-sm font-bold text-green-400 hover:bg-gray-800 hover:text-green-300 transition duration-300"
              :class="{ 'bg-gray-800 text-green-300': $route.path === '/signup' }">
              Signup
            </router-link>
          </template>

          <!-- Links visible only when logged in -->
          <template v-if="isLoggedIn">
            <router-link 
              to="/Nmap" 
              class="block px-4 py-2 text-sm font-bold text-green-400 hover:bg-gray-800 hover:text-green-300 transition duration-300"
              :class="{ 'bg-gray-800 text-green-300': $route.path === '/Nmap' }">
              Nmap
            </router-link>
            <router-link 
              to="/whois" 
              class="block px-4 py-2 text-sm font-bold text-green-400 hover:bg-gray-800 hover:text-green-300 transition duration-300"
              :class="{ 'bg-gray-800 text-green-300': $route.path === '/Nmap' }">
              Basic Tools
            </router-link>

            <router-link 
              to="/whatweb" 
              class="block px-4 py-2 text-sm font-bold text-green-400 hover:bg-gray-800 hover:text-green-300 transition duration-300"
              :class="{ 'bg-gray-800 text-green-300': $route.path === '/Nmap' }">
              whatweb
            </router-link>
            <router-link 
              to="/sublist3r" 
              class="block px-4 py-2 text-sm font-bold text-green-400 hover:bg-gray-800 hover:text-green-300 transition duration-300"
              :class="{ 'bg-gray-800 text-green-300': $route.path === '/bugTracking' }">
              sublist3r
            </router-link>

            <router-link 
              to="/dashboard" 
              class="block px-4 py-2 text-sm font-bold text-green-400 hover:bg-gray-800 hover:text-green-300 transition duration-300"
              :class="{ 'bg-gray-800 text-green-300': $route.path === '/dashboard' }">
              Dashboard
            </router-link>

            <router-link 
              to="/result" 
              class="block px-4 py-2 text-sm font-bold text-green-400 hover:bg-gray-800 hover:text-green-300 transition duration-300"
              :class="{ 'bg-gray-800 text-green-300': $route.path === '/result' }">
              Result
            </router-link>

            <router-link 
              to="/bugTracking" 
              class="block px-4 py-2 text-sm font-bold text-green-400 hover:bg-gray-800 hover:text-green-300 transition duration-300"
              :class="{ 'bg-gray-800 text-green-300': $route.path === '/bugTracking' }">
              Bug Reports
            </router-link>


            <button 
              @click="logout" 
              class="w-full text-left px-4 py-2 text-sm font-bold text-white bg-red-600 hover:bg-red-700 rounded transition duration-300">
              Logout
            </button>
          </template>
        </div>
      </div>
    </div>
  </nav>
</template>


<script>
import { ref } from 'vue';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';

export default {
  name: 'Navbar',
  setup() {
    const isLoggedIn = ref(false);
    const isSidebarOpen = ref(false);

    // Check login status and set isLoggedIn
    auth.onAuthStateChanged(user => {
      isLoggedIn.value = !!user;
    });

    const toggleSidebar = () => {
      isSidebarOpen.value = !isSidebarOpen.value;
    };

    const closeSidebar = () => {
      isSidebarOpen.value = false;
    };

    const logout = async () => {
      try {
        await signOut(auth);
      } catch (error) {
        console.error('Error logging out:', error);
      }
    };

    return { isLoggedIn, isSidebarOpen, toggleSidebar, closeSidebar, logout };
  }
};
</script>

<style scoped>
/* Navbar styling */
nav {
  background-color: #1f2937; /* Dark background */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

/* Link styles */
a {
  transition: color 0.3s ease, background-color 0.3s ease;
}

/* Active link style */
.router-link-active {
  background-color: #374151; /* Darker background for active link */
  border-radius: 0.5rem; /* Rounded corners for active link */
  color: #86efac; /* Green text color for active link */
}

a:hover {
  color: #86efac; /* Green color for hover effect */
  background-color: #374151; /* Darker background for hover */
  border-radius: 0.5rem; /* Rounded corners on hover */
}

/* Add padding for mobile screens */
.md\:pl-4 {
  padding-left: 1rem;
}

.md\:pr-4 {
  padding-right: 1rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
</style>
