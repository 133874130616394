<template>
  <div class="min-h-screen bg-gradient-to-r from-gray-900 to-gray-700 text-white flex flex-col items-center p-8">
    <h1 class="text-4xl font-bold mb-8">Dashboard</h1>

    <!-- Total Usage Section (Centered) -->
    <div class="w-full max-w-3xl bg-gray-800 p-6 rounded-lg shadow-lg border border-gray-600 mb-6">
      <div class="mb-6 text-center">
        <p v-if="!user" class="text-red-400 text-lg">No user is signed in.</p>
     
      </div>

      <div class="bg-gray-700 p-6 rounded-lg shadow-md border border-gray-500 mb-6 ">
  <h2 class="text-2xl font-semibold mb-4">Total Usage</h2>
  <div class="flex ">
    <div class="text-lg text-center">
      <p>CPU: <span class="font-bold text-cyan-400">{{ totalCPU.toFixed(2) }}%</span></p>
      <p>Memory: <span class="font-bold text-purple-400">{{ totalMemory.toFixed(2) }}MB</span></p>
    </div>
    <div class="flex flex-col ">
      <font-awesome-icon :icon="['fas', 'microchip']" class="text-green-400 text-3xl" />
      <font-awesome-icon :icon="['fas', 'memory']" class="text-blue-400 text-3xl mt-2" />
    </div>
  </div>
</div>


      <!-- New Request Count Card -->
      <div class="bg-gray-700 p-6 rounded-lg shadow-md border border-gray-500 mb-6">
        <h2 class="text-2xl font-semibold mb-4">Total Scan</h2>
        <p class="text-lg"> <span class="font-bold text-yellow-400">{{ requestCount }}</span></p>
      </div>
    </div>

    <!-- CPU and Memory Charts in Grid -->
    <div class="w-full max-w-6xl grid grid-cols-1 md:grid-cols-2 gap-6">
      <!-- CPU Usage Chart -->
      <div class="bg-gray-700 p-6 rounded-lg shadow-md border border-gray-500">
        <h2 class="text-2xl font-semibold mb-4">CPU Usage</h2>
        <canvas id="cpuChart" class="chart-canvas"></canvas>
      </div>

      <!-- Memory Usage Chart -->
      <div class="bg-gray-700 p-6 rounded-lg shadow-md border border-gray-500">
        <h2 class="text-2xl font-semibold mb-4">Memory Usage</h2>
        <canvas id="memoryChart" class="chart-canvas"></canvas>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { auth } from '../firebase';
import { Chart, registerables } from 'chart.js';

// Register Chart.js components
Chart.register(...registerables);

export default {
  name: 'Dashboard',
  setup() {
    const user = ref(null);
    const message = ref('Loading...');
    const totalCPU = ref(0.0);
    const totalMemory = ref(0.0);
    const dailyUsage = ref([]);
    const requestCount = ref(0); // New ref for request count

    onMounted(async () => {
      try {
        const currentUser = auth.currentUser;
        if (currentUser) {
          user.value = currentUser;
          await fetchTotalUsage();
          await fetchDailyUsage();
          await fetchtotalscan();
          renderCharts();
        } else {
          message.value = 'No user is signed in.';
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        message.value = 'Failed to fetch user data.';
      }
    });

    const fetchtotalscan = async () => {
      try {
        const idToken = await user.value.getIdToken();
        const response = await axios.get('https://api.nexusbot.app/count', {
          headers: { Authorization: `Bearer ${idToken}` },
        });
        requestCount.value = response.data[0]?.request_count || 0; // Update requestCount
      } catch (error) {
        console.error('Failed to fetch total scan data:', error);
      }
    };

    const fetchTotalUsage = async () => {
      try {
        const idToken = await user.value.getIdToken();
        const response = await axios.get('https://api.nexusbot.app/total_usage', {
          headers: { Authorization: `Bearer ${idToken}` },
        });
        const data = response.data[0] || {};
        totalCPU.value = data.total_cpu_percent || 0.0;
        totalMemory.value = data.total_memory_percent || 0.0;
      } catch (error) {
        console.error('Failed to fetch total usage:', error);
      }
    };

    const fetchDailyUsage = async () => {
      try {
        const idToken = await user.value.getIdToken();
        const response = await axios.get('https://api.nexusbot.app/daily_usage', {
          headers: { Authorization: `Bearer ${idToken}` },
        });
        dailyUsage.value = response.data || [];
        message.value = dailyUsage.value.length ? 'Data fetched successfully' : 'No data available';
      } catch (error) {
        console.error('Failed to fetch daily usage:', error);
        message.value = 'Failed to fetch daily usage data.';
      }
    };

    const renderCharts = () => {
      const cpuChartCtx = document.getElementById('cpuChart').getContext('2d');
      const memoryChartCtx = document.getElementById('memoryChart').getContext('2d');

      // Extract data
      const labels = dailyUsage.value.map(entry => new Date(entry.date).toLocaleDateString());
      const cpuData = dailyUsage.value.map(entry => entry.avg_cpu_percent);
      const memoryData = dailyUsage.value.map(entry => entry.avg_memory_percent);

      // Create CPU Usage Chart
      new Chart(cpuChartCtx, {
        type: 'bar',
        data: {
          labels,
          datasets: [
            {
              label: 'CPU Usage (%)',
              data: cpuData,
              backgroundColor: 'rgba(75, 192, 192, 0.7)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 2,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              title: {
                display: true,
                text: 'Date',
              },
              ticks: {
                autoSkip: true,
                maxTicksLimit: 5,
              },
            },
            y: {
              title: {
                display: true,
                text: 'CPU Usage (%)',
              },
              beginAtZero: true,
              ticks: {
                stepSize: 10,
              },
            },
          },
        },
      });

      // Create Memory Usage Chart
      new Chart(memoryChartCtx, {
        type: 'bar',
        data: {
          labels,
          datasets: [
            {
              label: 'Memory Usage (MB)',
              data: memoryData,
              backgroundColor: 'rgba(153, 102, 255, 0.7)',
              borderColor: 'rgba(153, 102, 255, 1)',
              borderWidth: 2,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              title: {
                display: true,
                text: 'Date',
              },
              ticks: {
                autoSkip: true,
                maxTicksLimit: 5,
              },
            },
            y: {
              title: {
                display: true,
                text: 'Memory Usage (%)',
              },
              beginAtZero: true,
              ticks: {
                stepSize: 10,
              },
            },
          },
        },
      });
    };

    return { user, message, totalCPU, totalMemory, requestCount };
  },
};
</script>
<style scoped>
/* Improved styling */
.bg-gradient-to-r {
  background: linear-gradient(to right, #1a202c, #2d3748);
}

.chart-canvas {
  width: 100% !important;
  height: 300px !important;
  margin: 0 auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.bg-gray-700, .bg-gray-800 {
  background-color: #2d3748;
}

.border-gray-500 {
  border-color: #4a5568;
}

h2 {
  color: #edf2f7;
}

p {
  font-size: 1.125rem;
}
</style>
