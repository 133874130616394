import { createRouter, createWebHistory } from 'vue-router'
import Login from '@/components/auth/Login.vue'
import Signup from '@/components/auth/Signup.vue'
import Nmap from '@/components/Nmap.vue'
import Navbar from '@/components/Navbar.vue'
import dashboard from '@/components/dashboard.vue'
import Result from '@/components/Result.vue'
import bugTracking from '@/components/bugTracking.vue'
import whois from '@/components/whois.vue'
import whatweb from '@/components/whatweb.vue'
import sublist3r from '@/components/sublist3r.vue'

const routes = [
    { path: '/', name: 'Nmap', component: Nmap },
  { path: '/Login', name: 'Login', component: Login },
  { path: '/Signup', name: 'Signup', component: Signup },
  { path: '/Nmap', name: 'Nmap', component: Nmap },
  { path: '/whois', name: 'whois', component: whois },
  { path: '/Navbar', name: 'Navbar', component: Navbar },
  { path: '/dashboard', name: 'dashboard', component: dashboard },
  { path: '/Result', name: 'Result', component: Result },
  { path: '/bugTracking', name: 'bugTracking', component: bugTracking },
  { path: '/whatweb', name: 'whatweb', component: whatweb },
  { path: '/sublist3r', name: 'sublist3r', component: sublist3r },
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
