<template>
  <div class="flex items-center justify-center min-h-screen bg-gray-900 p-4">
    <!-- Main Card -->
    <div class="bg-gray-800 rounded-3xl shadow-xl p-8 w-full max-w-md mx-4">
      <!-- Welcome Text -->
      <h1 class="text-3xl font-bold text-center text-white mb-2">Welcome Back!</h1>
      <p class="text-sm text-center text-gray-400 mb-8">We missed you! Please sign in to continue.</p>

      <!-- Form -->
      <form @submit.prevent="handleLogin" class="space-y-6">
        <!-- Username Input -->
        <div>
          <input 
            type="text" 
            v-model="email" 
            placeholder="Enter username" 
            class="w-full px-4 py-3 rounded-lg bg-gray-700 border border-transparent text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500"
            required 
          />
        </div>

        <!-- Password Input with Icon -->
        <div class="relative">
          <input 
            type="password" 
            v-model="password" 
            placeholder="Password" 
            class="w-full px-4 py-3 rounded-lg bg-gray-700 border border-transparent text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500"
            required 
          />
          <button type="button" class="absolute right-3 top-3">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.522 5 12 5c4.478 0 8.268 2.943 9.542 7-.06.224-.114.447-.172.668M12 19c-4.478 0-8.268-2.943-9.542-7" />
            </svg>
          </button>
        </div>

        <!-- Sign In Button -->
        <button 
          type="submit" 
          class="w-full bg-green-600 text-white py-3 rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-300"
        >
          Sign In
        </button>

        <!-- Not a member and Forgot Password -->
        <div class="flex flex-col sm:flex-row justify-between items-center mt-4 text-sm text-gray-400">
          <p class="mb-2 sm:mb-0">
            Not a member? 
            <router-link to="/signup" class="text-green-500 hover:text-green-400 ml-1">Register now</router-link>
          </p>
          <button @click="showForgotPasswordModal" class="text-gray-500 hover:text-gray-400 sm:ml-4">Forgot Password</button>
        </div>
      </form>

      <!-- Or Continue with -->
      <div class="text-center mt-6">
        <p class="text-gray-500 mb-4">Or continue with</p>
        <div class="flex justify-center space-x-4">
          <!-- Google Icon -->
          <button @click="handleGoogleSignIn" class="bg-gray-700 p-3 rounded-full shadow-md">
            <img src="https://firebasestorage.googleapis.com/v0/b/nexusbot-connect.appspot.com/o/google-removebg-preview.png?alt=media&token=2bfb4876-c5df-4d3f-8a46-6c4ff9075fb9" alt="Google" class="h-8 w-8">
          </button>
        </div>
      </div>
    </div>

    <!-- Forgot Password Modal -->
    <div v-if="isForgotPasswordVisible" class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div class="bg-gray-800 rounded-lg p-6 w-full max-w-sm">
        <h2 class="text-xl font-bold text-white mb-4">Reset Password</h2>
        <input 
          type="email" 
          v-model="forgotPasswordEmail" 
          placeholder="Enter your email" 
          class="w-full px-4 py-3 rounded-lg bg-gray-700 border border-transparent text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500"
          required 
        />
        <div class="flex justify-between mt-4">
          <button @click="sendPasswordResetEmail" class="bg-green-600 text-white py-2 px-4 rounded-lg hover:bg-green-700">Send</button>
          <button @click="closeForgotPasswordModal" class="bg-gray-600 text-white py-2 px-4 rounded-lg hover:bg-gray-700">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../firebase';

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      isForgotPasswordVisible: false,
      forgotPasswordEmail: ''
    };
  },
  methods: {
    async handleLogin() {
      try {
        const userCredential = await signInWithEmailAndPassword(auth, this.email, this.password);
        const user = userCredential.user;

        if (user.emailVerified) {
          this.$router.push('/Nmap');
        } else {
          alert('Please verify your email before logging in.');
          await auth.signOut();
        }
      } catch (error) {
        console.error("Error logging in:", error.message);
      }
    },
    async handleGoogleSignIn() {
      const provider = new GoogleAuthProvider();
      try {
        const result = await signInWithPopup(auth, provider);
        console.log('User Info:', result.user);
        this.$router.push('/Nmap');
      } catch (error) {
        console.error("Error with Google Sign-In:", error.message);
      }
    },
    showForgotPasswordModal() {
      this.isForgotPasswordVisible = true;
    },
    closeForgotPasswordModal() {
      this.isForgotPasswordVisible = false;
      this.forgotPasswordEmail = ''; // Clear the email input
    },
    async sendPasswordResetEmail() {
      try {
        await sendPasswordResetEmail(auth, this.forgotPasswordEmail);
        alert('Password reset email sent! Please check your inbox.');
        this.closeForgotPasswordModal();
      } catch (error) {
        console.error("Error sending password reset email:", error.message);
        alert('Error sending password reset email. Please try again.');
      }
    }
  }
}
</script>

<style scoped>
/* Custom styles for login page */
.bg-gradient-to-r {
  background: linear-gradient(to right, #1f2937, #374151); /* Dark gradient */
}

form input:focus {
  border: none;
  outline: none;
}

button svg {
  transition: transform 0.3s ease;
}

button:hover svg {
  transform: translateX(5px);
}

.p-4 {
  padding: 1rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}
</style>
