<template>
  <div class="min-h-screen bg-gray-900 text-white flex flex-col items-center p-4">
    <h1 class="text-3xl font-bold mb-6">WhatWeb Scan</h1>

    <div class="w-full max-w-2xl bg-gray-800 p-6 rounded-lg shadow-lg">
      <!-- Target input (Mandatory) -->
      <div class="mb-4">
        <label class="block text-gray-300 mb-2">Enter Targets:</label>
        <input
          v-model="target"
          type="text"
          placeholder="Enter URLs, IPs"
          class="w-full p-3 bg-gray-700 rounded-lg text-white placeholder-gray-400"
        />
      </div>

      <!-- Action buttons -->
      <div class="flex space-x-4 mb-4">
        <button
          :disabled="!idToken || !target"
          @click="sendWhatWebRequest"
          class="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-lg shadow-md disabled:opacity-50"
        >
          Scan
        </button>
        <button
          @click="clearFields"
          class="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg shadow-md"
        >
          Reset
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { auth } from '../firebase'; // Ensure the path to firebase is correct

export default {
  name: 'WhatWebScan',
  data() {
    return {
      target: '', // User input for target (mandatory)
      idToken: null, // Firebase ID token for authentication
    };
  },
  methods: {
    async fetchIdToken() {
      try {
        const user = auth.currentUser;
        if (user) {
          this.idToken = await user.getIdToken();
        } else {
          alert('No user is signed in.');
        }
      } catch (error) {
        console.error('Error fetching ID token:', error);
      }
    },
    async sendWhatWebRequest() {
      if (!this.target) {
        alert('Target is required.');
        return;
      }

      try {
        // Preparing the payload
        const payload = {
          target: this.target, // Mandatory target (URL, IPs)
        };

        // Sending the request to the backend with Firebase ID token
        const response = await axios.post(
          'https://api.nexusbot.app/whatweb',
          payload,
          {
            headers: {
              Authorization: `Bearer ${this.idToken}`, // ID token for authentication
              'Content-Type': 'application/json',
            },
          }
        );

        // Handling the success response
        alert(`WhatWeb scan request sent successfully.\nTask ID: ${response.data.task_id}\nMessage: ${response.data.message}`);
      } catch (error) {
        // Handling errors, including specific 400 errors
        if (error.response && error.response.status === 400) {
          alert(error.response.data.error);
        } else {
          alert('An unexpected error occurred.');
        }
      }
    },
    clearFields() {
      // Clear the target input field
      this.target = '';
    },
  },
  created() {
    // Fetch the Firebase ID token when the component is created
    this.fetchIdToken();
  },
};
</script>
