<template>
  <div class="min-h-screen bg-gray-900 text-white flex flex-col items-center p-4">
    <h1 class="text-3xl font-bold mb-6">Network Tools</h1>

    <div class="w-full max-w-2xl bg-gray-800 p-6 rounded-lg shadow-lg">
      <div class="mb-4">
        <input v-model="target" type="text" placeholder="Domain or IP address"
          class="w-full p-3 bg-gray-700 rounded-lg text-white placeholder-gray-400" />
      </div>

      <div class="mb-4">
        <select v-model="selectedTool" class="w-full p-3 bg-gray-700 rounded-lg text-white">
          <option disabled value="">Select a tool</option>
          <option value="1">Ping</option>
          <option value="2">WHOIS</option>
          <option value="3">Traceroute</option>
          <option value="4">DNS Lookup</option>
          <option value="5">Reverse DNS</option>
          <option value="6">SSL Checker</option>
        </select>
      </div>

      <div class="flex space-x-4 mb-4">
        <button :disabled="!idToken || !selectedTool || !target" @click="runNetworkTool"
          class="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-lg shadow-md disabled:opacity-50">
          Run
        </button>
        <button @click="clearFields"
          class="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg shadow-md">
          Reset
        </button>
        <button @click="showHelp"
          class="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg shadow-md">
          Help
        </button>
      </div>

      <div v-if="output" class="bg-gray-700 p-4 rounded-lg text-white mt-4">
        <pre>{{ output }}</pre>
      </div>
    </div>

    <!-- Help Modal -->
    <div v-if="showHelpModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div class="bg-gray-800 text-white p-6 rounded-lg shadow-lg max-w-2xl w-full">
        <h2 class="text-2xl font-bold mb-4">Tool Help</h2>
        <pre class="bg-gray-700 p-4 rounded-lg overflow-y-auto max-h-80 text-sm text-left"
          style="white-space: pre-wrap;">
          Select a tool from the dropdown menu. You can perform actions like Ping, WHOIS lookup, Traceroute, DNS Lookup, Reverse DNS, and SSL Checker.
          Input the target domain or IP address and click "Run" to perform the selected network action.
        </pre>
        <button @click="closeHelp" class="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg mt-4">
          Close
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { auth } from '../firebase'; // Adjust the path as needed

export default {
  name: 'NetworkTools',
  data() {
    return {
      target: '',
      selectedTool: '', // Will store the selected tool as a number (1, 2, etc.)
      idToken: null,
      showHelpModal: false,
      output: ''
    };
  },
  methods: {
    async fetchIdToken() {
      try {
        const user = auth.currentUser;
        if (user) {
          this.idToken = await user.getIdToken();
        } else {
          alert('No user is signed in.');
        }
      } catch (error) {
        console.error('Error fetching ID token:', error);
      }
    },
    async runNetworkTool() {
      try {
        const response = await axios.post(
          'https://api.nexusbot.app/networktool',
          {
            target: this.target,
            tool: this.selectedTool
            
          },
          {
            headers: {
              Authorization: `Bearer ${this.idToken}`,
              'Content-Type': 'application/json',
            }
          }
        );

        this.output = response.data.result; // Display the output returned by the API
      } catch (error) {
        console.error('Error running tool:', error);
        this.output = 'An error occurred while performing the operation.';
      }
    },
    clearFields() {
      this.target = '';
      this.selectedTool = '';
      this.output = '';
    },
    showHelp() {
      this.showHelpModal = true;
    },
    closeHelp() {
      this.showHelpModal = false;
    }
  },
  created() {
    this.fetchIdToken();
  }
};
</script>

<style scoped>
/* Additional styles for modal */
pre {
  margin: 0;
  padding: 1rem;
  overflow: hidden;
  text-align: left;
}

.overflow-y-auto {
  overflow-y: auto;
  max-height: 80vh;
}
</style>
