<template>
  <div class="min-h-screen bg-gray-900 text-white flex flex-col items-center p-4">
    <h1 class="text-3xl font-bold mb-6">Nmap Scan</h1>

    <div class="w-full max-w-2xl bg-gray-800 p-6 rounded-lg shadow-lg">
      <div class="mb-4">
        <input
          v-model="target"
          type="text"
          placeholder="IP address or domain"
          class="w-full p-3 bg-gray-700 rounded-lg text-white placeholder-gray-400"
        />
      </div>

      <div class="mb-4">
        <textarea
          v-model="options"
          placeholder="Options (editable)"
          class="w-full p-3 bg-gray-700 rounded-lg text-white placeholder-gray-400"
        ></textarea>
      </div>

      <div class="flex space-x-4 mb-4">
        <button
          :disabled="!idToken"
          @click="sendScanRequest"
          class="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-lg shadow-md disabled:opacity-50"
        >
          Scan
        </button>
        <button
          @click="clearFields"
          class="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg shadow-md"
        >
          Reset
        </button>
        <button
          @click="showHelp"
          class="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg shadow-md"
        >
          Help
        </button>
      </div>

      <div class="flex flex-wrap gap-2">
        <div
          v-for="(option, index) in scanOptions"
          :key="index"
          @click="toggleOption(index)"
          :class="['cursor-pointer px-4 py-2 rounded-full text-sm font-semibold', { 'bg-green-500': selectedOptions.includes(index), 'bg-gray-700': !selectedOptions.includes(index) }]"
        >
          {{ option }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { auth } from '../firebase'; // Adjust the path as needed

export default {
  name: 'App',
  data() {
    return {
      target: '',
      options: '',
      idToken: null,
      scanOptions: [
        'Fast scan',
        'Ping scan',
        'Version Detection',
        'OS Detection',
        'Aggressive Scan',
        'Script Scan',
        'Packet Trace',
        'IP Packet fragment',
        'Top 100 Ports',
        'All Ports'
      ],
      scanOptionValues: [
        '-F',
        '-sn',
        '-sV',
        '-O',
        '-A',
        '-sC',
        '--packet-trace',
        '-f',
        '--top-ports 100',
        '-p-'
      ],
      selectedOptions: []
    };
  },
  methods: {
    async fetchIdToken() {
      try {
        const user = auth.currentUser;
        if (user) {
          this.idToken = await user.getIdToken();
        } else {
          alert('No user is signed in.');
        }
      } catch (error) {
        console.error('Error fetching ID token:', error);
      }
    },
    async sendScanRequest() {
      try {
        const targetWithOptions = ` ${this.options} ${this.target}`;
        const response = await axios.post(
          'https://api.nexusbot.app/scan',
          { target: targetWithOptions },
          {
            headers: {
              'Authorization': `Bearer ${this.idToken}`,
              'Content-Type': 'application/json'
            }
          }
        );
        alert(`Scan request sent successfully.\nTask ID: ${response.data.task_id}\nMessage: ${response.data.message}`);
      } catch (error) {
         // Handle errors
         if (error.response && error.response.status === 400) {
          alert(error.response.data.error);
        } else {
          alert('An unexpected error occurred.');
        }
      }
    },
    toggleOption(index) {
      if (this.selectedOptions.includes(index)) {
        this.selectedOptions = this.selectedOptions.filter(i => i !== index);
      } else {
        this.selectedOptions.push(index);
      }
      this.updateOptionsTextField();
    },
    updateOptionsTextField() {
      this.options = this.selectedOptions
        .map(index => this.scanOptionValues[index])
        .join(' ');
    },
    clearFields() {
      this.target = '';
      this.options = '';
      this.selectedOptions = [];
    },
    showHelp() {
      alert('Help section');
    }
  },
  created() {
    this.fetchIdToken();
  }
};
</script>

<style scoped>
/* Additional styles if needed */
</style>
