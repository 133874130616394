import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDETo6Fazxt_7ivUfd7IQPKryle-4lKPTk",
    authDomain: "nexusbot-connect.firebaseapp.com",
    databaseURL: "https://nexusbot-connect-default-rtdb.firebaseio.com",
    projectId: "nexusbot-connect",
    storageBucket: "nexusbot-connect.appspot.com",
    messagingSenderId: "869813424042",
    appId: "1:869813424042:web:71d8d28eae2b6a5ccb65d3",
    measurementId: "G-X2P86K572G"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
