<template>
  <div class="min-h-screen bg-gray-900 text-white">
    <!-- App Bar -->
    <header class="bg-gray-900 text-white">
      <div class="max-w-7xl mx-auto px-4 py-6 sm:px-6 lg:px-8">
        <h1 class="text-3xl font-bold text-center">Scan Report</h1>
      </div>
    </header>

    <!-- Search Bar -->
    <div class="max-w-4xl mx-auto rounded-lg">
      <input
        type="text"
        v-model="searchQuery"
        placeholder="Search by Target"
        class="w-full p-2 mb-4 rounded-lg bg-gray-700 text-white"
      />
    </div>

    <!-- Loading and Results Display -->
    <main class="max-w-4xl bg-gray-700 mx-auto p-6 rounded-lg mt-6">
      <div v-if="isLoading" class="flex justify-center items-center h-96">
        <div class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-blue-600"></div>
      </div>
      <div v-else-if="filteredResults.length === 0" class="text-center">
        <p>No results found.</p>
      </div>
      <div v-else>
        <div v-for="(result, index) in filteredResults" :key="index" class="mb-6">
          <h2 class="text-blue-400 text-xl font-bold mb-4"> {{ result.Target || 'N/A' }}({{ result.Tool || 'N/A'}})</h2>
          <div class="bg-gray-900 p-4 rounded-lg shadow-lg">
            <p class="text-left"><strong>Target:</strong> {{ result.Target || 'N/A' }}</p>
            <p class="text-left"><strong>Scan Date:</strong> {{ result.scan_datetime || 'N/A' }}</p>
            <p class="text-left"><strong>Tool:</strong> {{ result.Tool || 'N/A' }}</p>
            <p class="text-left"><strong>Status:</strong> <span :class="statusClass(result.status)">{{ result.status }}</span></p>
            <p class="text-left"><strong>Task ID:</strong> {{ result.task_id || 'N/A' }}</p>
            <p class="text-left"><strong>CPU Usage:</strong> {{ formatUsage(result.result?.avg_cpu_usage) }}</p>
            <p class="text-left"><strong>Memory Usage:</strong> {{ formatUsage(result.result?.avg_memory_usage) }}</p>

            <div class="flex justify-end space-x-4 mt-4">
              <button @click="generatePdf(result.result?.output || 'No output available')" class="btn btn-green">Output</button>
              <button @click="shareOutput(result.result?.output || 'No output available')" class="btn btn-blue">Share</button>
              <button @click="copyOutput(result.result?.output || 'No output available')" class="btn btn-copy">
  <i class="fas fa-copy"></i> Copy
</button>

            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import axios from 'axios';
import { auth } from '../firebase';
import { jsPDF } from 'jspdf';

export default {
  name: 'ResultsPage',
  setup() {
    const results = ref([]);
    const searchQuery = ref('');
    const isLoading = ref(true);
    const user = ref(null);
    let intervalId = null;

    const fetchData = async () => {
      try {
        const idToken = await user.value.getIdToken();
        const response = await axios.get('https://api.nexusbot.app/results', {
          headers: { Authorization: `Bearer ${idToken}` },
        });
        results.value = response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        isLoading.value = false;
      }
    };

    const filteredResults = computed(() => {
      let filtered = !searchQuery.value
        ? results.value
        : results.value.filter((result) =>
            result.Target.toLowerCase().includes(searchQuery.value.toLowerCase())
          );

      filtered.sort((a, b) => new Date(b.scan_datetime) - new Date(a.scan_datetime));
      return filtered;
    });

    onMounted(() => {
      const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
        if (currentUser) {
          user.value = currentUser;
          await fetchData();
          intervalId = setInterval(fetchData, 5000);
          unsubscribe();
        }
      });
    });

    onUnmounted(() => {
      if (intervalId) clearInterval(intervalId);
    });

    const generatePdf = (text) => {
      const doc = new jsPDF();
      doc.setFontSize(10);
      const splitText = doc.splitTextToSize(text, 180);
      let y = 10;
      const lineHeight = 7;
      const pageHeight = doc.internal.pageSize.height;

      splitText.forEach((line) => {
        if (y + lineHeight > pageHeight - 10) {
          doc.addPage();
          y = 10;
        }
        doc.text(line, 10, y);
        y += lineHeight;
      });

      doc.save('output.pdf');
    };

    const shareOutput = async (text) => {
      try {
        await navigator.share({ text });
      } catch (error) {
        console.error('Error sharing output:', error);
      }
    };

    const copyOutput = async (text) => {
      try {
        await navigator.clipboard.writeText(text);
        alert('Output copied to clipboard!');
      } catch (error) {
        console.error('Error copying output:', error);
      }
    };

    const formatUsage = (usage) => (usage != null ? usage.toFixed(2) : 'N/A');
    const statusClass = (status) => (status === 'SUCCESS' ? 'text-green-400' : 'text-red-400');

    return {
      results,
      searchQuery,
      filteredResults,
      isLoading,
      generatePdf,
      shareOutput,
      copyOutput,
      formatUsage,
      statusClass,
    };
  },
};

</script>
<style scoped>
.btn {
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}

.btn-green {
  background-color: #10b981;
  color: white;
}

.btn-blue {
  background-color: #3b82f6;
  color: white;
}

.btn-copy {
  background-color: #fbbf24; /* Yellow background */
  color: black; /* Black text color */
}

i {
  margin-right: 0.5rem;
}
</style>
